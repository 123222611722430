.HistoryAlerts{
  padding-block: 24px;
  .alert-redirect{
    text-decoration: none;
    display: flex;
    gap: 10px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 70px;
    border: 1px solid #E5E7EB;
    background: #F3F4F6;
  }
  .remaining-count{
    display:flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    padding: 8px;
    border-radius: 50%;
    border: 1px solid #E5E7EB;
    background: #F3F4F6;
    color: #757575;
    font-size: 14px;
    cursor: pointer;
  }
  .truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
}