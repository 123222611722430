.EmptyBox{
  display: flex;
  width: 100%;
  padding: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
  border: 1px solid  #E7E7E7;
  background: #FFF;
}