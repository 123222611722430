@import 'src/tokens';
@import 'src/mixins';
@import '../input-variables';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition-150;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 0;
  width: fit-content;
  height: 2rem;
  padding: 0;
  outline: none;
  background-color: $color-white;
  color: #2b1f0a;
  border: 1px solid $text-on-white--primary;
  border-radius: 10px;
  cursor: pointer;
  &__text {
    font-family: 'Open Sans';
    margin-right: auto;
    margin-left: auto;
    white-space: nowrap;
    padding: 0 1rem;

    @media screen and (max-width: 699px) {
      font-size: 12px;
    }
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
  }
  &:not(:disabled):hover {
    border-color: #f5c537;
    color: #f5c537;
  }
  &__link {
    text-decoration: none;
  }

  @include focus-ring;
}

.Button--has-right-icon .Button {
  &__text {
    padding-right: 0 !important;
  }
}

.Button--has-left-icon .Button {
  &__text {
    padding-left: 0 !important;
  }
}

.Button--primary {
  background-color: $color-primary;
  color: $text-on-primary;
  border-color: $color-primary;
  &:disabled{
   opacity: 0.3;
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    background-color: #f5c537;
    color: $text-on-primary-darker;
    border-color: #f5c537;
  }
}

.Button--danger {
  background-color: $color-danger;
  color: $text-on-danger;
  border-color: $color-danger;
  &:not(:disabled):hover {
    background-color: $color-danger-darker;
    color: $text-on-danger-darker;
    border-color: $color-danger-darker;
  }
}

.Button--disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.Button--disabled.cloudsponge-launch {
  border: none;
  opacity: 0.25;
  cursor: not-allowed;
}

.Button--rounded {
  border-radius: 1000rem;
}

.Button--text {
  border-color: transparent;
  background-color: transparent;
  &:not(:disabled):hover {
    background-color: $color-primary-050;
    color: $text-on-primary-050--vivid;
    border-color: $text-on-primary-050--vivid;
  }
  &:disabled{
    cursor: not-allowed;
  }
}
.Button--link {
  border:none;
  background-color: transparent;
  color: #007B94;
  &:not(:disabled):hover {
    color: #0B687F;
  }
  &:disabled{
    color: #B0B0B0;
    cursor: not-allowed;
  }
}

.Button--s-size {
  font-size: $text-size-xs;
  font-weight: $input-font-weight-s;
  letter-spacing: 0.015rem;
  height: var(--input-height-s);

  @media screen and (min-width: 3200px) {
    height: calc(var(--input-height-s) + 1rem);
    font-size: calc(var(--text-size-s) + 1rem) !important;
  }

  .Button__text ~ &:not(.Button--has-right-icon) {
    padding: 0 $text-size-xs;

    @media screen and (min-width: 3200px) {
      padding: 0 calc(var(--text-size-xs) + 1rem);
    }
  }
}

.Button--s-size .Button {
  &__icon-wrapper {
    width: calc(1.5rem - var(--border-width-default) - var(--border-width-default));
    height: calc(1.5rem - var(--border-width-default) - var(--border-width-default));
    @media screen and (min-width: 3200px) {
      width: calc(2.5rem - var(--border-width-default) - var(--border-width-default));
      height: calc(2.5rem - var(--border-width-default) - var(--border-width-default));
    }
  }
}

.Button--m-size {
  font-size: $text-size-m;
  font-weight: $input-font-weight-m;
  line-height: 1;
  height: var(--input-height-m);

  @media screen and (min-width: 3200px) {
    height: calc(var(--input-height-m) + 1rem);
    font-size: calc(var(--text-size-m) + 1rem) !important;
  }

  .Button__text ~ &:not(.Button--has-right-icon) {
    padding: 0 $text-size-m;
    @media screen and (min-width: 3200px) {
      padding: 0 calc(var(--text-size-m) + 1rem);
    }
  }
}

.Button--m-size .Button {
  &__icon-wrapper {
    width: calc(2rem - var(--border-width-default) - var(--border-width-default));
    height: calc(2rem - var(--border-width-default) - var(--border-width-default));
    @media screen and (min-width: 3200px) {
      width: calc(4rem - var(--border-width-default) - var(--border-width-default));
      height: calc(4rem - var(--border-width-default) - var(--border-width-default));
    }
  }
}

.Button--l-size {
  font-size: $text-size-l;
  font-weight: $input-font-weight-l;
  line-height: 1;
  height: var(--input-height-l);

  @media screen and (min-width: 3200px) {
    height: calc(var(--input-height-l) + 1rem);
    font-size: calc(var(--text-size-l) + 1rem) !important;
  }

  .Button__text ~ &:not(.Button--has-right-icon) {
    padding: 0 $text-size-l;

    @media screen and (min-width: 3200px) {
      padding: 0 calc(var(--text-size-l) + 1rem);
    }
  }
}

.Button--l-size .Button {
  &__icon-wrapper {
    width: calc(3rem - var(--border-width-default) - var(--border-width-default));
    height: calc(3rem - var(--border-width-default) - var(--border-width-default));

    @media screen and (min-width: 3200px) {
      width: calc(5rem - var(--border-width-default) - var(--border-width-default));
      height: calc(5rem - var(--border-width-default) - var(--border-width-default));
    }
  }
}

.Button--xl-size {
  font-size: $text-size-xl;
  font-weight: $input-font-weight-xl;
  line-height: 1;
  height: var(--input-height-xl);

  @media screen and (min-width: 3200px) {
    height: calc(var(--input-height-xl) + 1rem);
    font-size: calc(var(--text-size-xl) + 1rem) !important;
  }

  .Button__text ~ &:not(.Button--has-right-icon) {
    padding: 0 $text-size-xl;

    @media screen and (min-width: 3200px) {
      padding: 0 calc(var(--text-size-xl) + 1rem);
    }
  }
}

.Button--xl-size .Button {
  &__icon-wrapper {
    width: calc(4rem - var(--border-width-default) - var(--border-width-default));
    height: calc(4rem - var(--border-width-default) - var(--border-width-default));

    @media screen and (min-width: 3200px) {
      width: calc(6rem - var(--border-width-default) - var(--border-width-default));
      height: calc(6rem - var(--border-width-default) - var(--border-width-default));
    }
  }
}
