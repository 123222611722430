.banner-background-wrapper {
  &-label {
    color: #757575;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .color-field-wrapper {
    position: relative;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all .2s;
    padding: 4px;

    .color-picker-icon {
      position: absolute;
      bottom: -1px;
      right: -1px;
    }

    &.selected {
      border-color: #52C41A;
    }

    .p-colorpicker.p-component.p-colorpicker-overlay {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: none;

      input {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: none;
      }
    }

    .color-field {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      padding: 0;
      cursor: pointer;
      border-radius: 50%;
    }
  }
}