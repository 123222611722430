
.TableSelectionWrapper {
  &__selected-row {
    text-transform: capitalize;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    color: #262626 !important;
    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }

  &__selected-row-delete {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 1.5 !important;
    color: #FF2D38 !important;

    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }
}
