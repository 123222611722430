.creative-uploader {
  width: 226px;

  &-title-container {
    align-self: flex-start;
  }

  &-title {
    color: #262626;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .upload-photo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 158px;
    cursor: pointer;
    border: 1px solid #E7E7E7;
    background: #F6F6F6;

    .uploader-mask {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .68);
      opacity: 0;
      transition: opacity .2s;

      .re-crop-image {
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 0;
        display: flex;
      }

      .uploader-root {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
    }

    &:hover {
      .uploader-mask {
        opacity: 1;
      }
    }

    .creative-uploader-logo {
      height: 105px;
    }

    .uploaded-file {
      width: 100%;
      height: 100%;
    }

    .uploaded-file {
      object-fit: contain;
    }
  }
}