.deleteConfirmationModal {
  overflow: hidden;
  position: relative;
  width: 550px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;

  &__heading {
    padding: 0 10%;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 25px !important;
    line-height: 35px !important;
    color: #000000 !important;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 63%;
  }

  .confirmation-note {
    max-width: 300px;
    text-align: center;
    span {
      color: #9a9a9a;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }

    span.note-name {
      color: #faad14;
    }
  }
}
.rightImage {
  position: absolute;
  bottom: -18%;
  right: 0px;
  width: 32%;
}
.leftImage {
  position: absolute;
  top: -20px;
  left: 15px;
  width: 25%;
}
