.orderline-status-tag {
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  background: #FFFDEA;

  .orderline-status-text {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}