.text-input-container {
  .input-wrapper {
    min-height: 67px;
  }

  .optional-info {
    color: #757575;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  label {
    color: #262626;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .creative-field-input-error.p-error {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
  }

  .creative-field-input {
    color: #040404;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: normal;

    border-radius: 6px;
    border: 1px solid #E7E7E7;
    background: #FEFEFE;

    &.p-inputtext {
      transition: all 0.3s;

      &:not(.p-invalid) {
        &:hover,
        &:active,
        &:focus {
          border-color: #ffab03;
        }
        &:focus {
          box-shadow: 0 0 0 2px rgba(255, 235, 5, 0.1);
        }
      }

      &.p-invalid {
        border-color: #e24c4c;
        &:enabled:focus {
          box-shadow: 0 0 0 2px rgba(255,38,5,0.06);
        }
      }
    }
  }
}