.performance-stat-container {
  padding: 1rem 1.5rem;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  background: #FFFFFF;

  .performance-stat-name {
    color: #3F3F42;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .performance-stat-value {
    color: #262534;
    font-family: Inter, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
  }

  .stat-chart-color {
    width: 18px;
    height: 18px;
    border-radius: 2px;
  }

  .stat-chart {
    width: 112px;
  }

  .stat-chart-info {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &-label {
      color: #757575;
    }

    &-value {
      color: #111827;
    }
  }
}