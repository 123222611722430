.Modal__content.magic-creative-generator-modal {
  position: relative;
  width: 1072px;
  height: 874px;
  max-height: calc(-5rem + 100vh);
  border-radius: 6px;

  .Modal__scrollable-content {
    display: flex;
    height: 100%;

    .creative-generator-modal-sider, .creative-generator-modal-content {
      height: 100%;
    }
    .creative-generator-modal-sider {
      flex: 0 0 265px;
      max-width: 265px;
      min-width: 265px;
      width: 265px;
      padding: 32px 12px 0 32px;
      border-radius: 6px 0 0 6px;
      border-right: 1px solid var(--Neutrals-100, #E7E7E7);

      .creative-generator-step {
        color: #262626;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      .creative-generator-title {
        color: #757575;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .creative-generator-modal-content {
      flex: auto;
      overflow: hidden;

      > .Box.Box--display-flex {
        height: 100%;

        .creative-generator-modal-content-inner {
          height: 100%;
          overflow: auto;
          padding: 40px 40px 32px 32px;
        }
      }

      &-footer {
        padding: 20px;
        border-top: 1px solid #E7E7E7;
      }
    }

    .magic-creative-stepper {
      .step-item {
        width: fit-content;

        &.step-item-active, &.step-item-checked {
          .step-item-header {
            .step-item-label {
              color: #007B94
            }
          }
        }

        &.step-item-active {
          .step-item-header {
            .step-item-label {
              font-weight: 700;
            }
          }
        }

        &.step-item-checked {
          .step-item-separator {
            &-divider {
              border-color: #007B94
            }
          }
        }

        .step-item-header {
          display: flex;
          align-items: center;
          gap: 10px;
          border: none;
          background: transparent;
          padding: 0;

          svg {
            font-size: 32px;
            color: #007B94
          }

          .step-item-label {
            color: #B0B0B0;
            text-align: center;
            font-family: "Open Sans", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
          }

          .step-item-step-index {
            width: 32px;
            height: 32px;
            border: 2px solid #B0B0B0;
            border-radius: 50%;

            color: #B0B0B0;
            text-align: center;
            font-family: Inter, sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .step-item-separator {
          width: 32px;
          height: 50px;

          &-divider {
            height: 100%;
            border-left: 2px solid #E7E7E7;
          }
        }
      }
    }
  }
}