.PersonalInfoForm {
  display: flex;
  position: relative;
  overflow: hidden;

  padding: 2rem 1rem;
  background-image: url('../../assets/Images/Maskgroup.svg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 50%;

  &.mls-system-form {
    flex-direction: column;
    padding: 32px 42px;
    overflow: hidden auto;
    position: relative;

    background-image: none;

    .PersonalInfoForm__inputs {
      padding: 0;
    }
  }

  &__cross {
    position: absolute;
    top: 17px;
    right: 20px;
    width: 20px;
    cursor: pointer;

    @media screen and (min-width: 3200px) {
      width: 40px;
    }
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    padding-top: 3rem;
  }

  @media (max-width: 699px) {
    padding-top: 2rem;
    padding-right: 0;
    padding-left: 0;
    max-width: 310px;
  }

  .inputs-wrapper {
    display: flex;

    &:not(.mls-system-inputs) {
      align-items: flex-end;
      gap: 2rem;
    }

    &.mls-system-inputs {
      flex-wrap: wrap;
      gap: 1.5rem 1rem;

      .input-wrapper {
        min-height: initial;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .input-wrapper {
      min-height: 90px;
    }

    .label-input-field-container, .label-input-select-container {
      min-width: 336px;
    }
  }

  div:has(> .PersonalInfoForm__submit) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__header {
    max-width: 350px;
    padding: 3rem 0 22rem;

    .heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 40px !important;
      line-height: 54px !important;
      display: flex !important;
      align-items: center !important;
      color: #ffab03 !important;
      margin-bottom: 1rem;

      @media screen and (min-width: 3200px) {
        font-size: 50px !important;
      }

      @media (max-width: 1050px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: 699px) {
        margin-bottom: 0.5rem;
        font-size: 18px !important;
      }
    }
    .sub_heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 22px !important;
      line-height: 1.35 !important;
      color: #818181 !important;

      @media screen and (min-width: 3200px) {
        font-size: 32px !important;
      }
    }

    .sub_heading {
      @media (max-width: 699px) {
        font-size: 14px !important;
      }
    }
  }

  &:not(.mls-system-form) &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 2rem;
    border-right: 1px solid #c6cbcd;

    @media (max-width: 1400px) {
      padding: 0 2rem;
    }

    @media (max-width: 1050px) {
      width: 100%;
      padding: 1rem;
      padding-top: 0;
    }
  }

  &__description {
    .heading {
      color: #FFAB03;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media screen and (min-width: 3200px) {
        font-size: 50px !important;
      }

      @media (max-width: 1050px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: 699px) {
        margin-bottom: 0.5rem;
        font-size: 18px !important;
      }
    }
    .sub_heading {
      color: #818181;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;

      max-width: 730px;

      @media screen and (min-width: 3200px) {
        font-size: 32px !important;
      }
    }

    .sub_heading {
      @media (max-width: 699px) {
        font-size: 14px !important;
      }
    }

    @media (max-width: 1400px) {
      padding: 0 2rem;
    }

    @media (max-width: 1050px) {
      width: 100%;
      padding: 1rem;
      padding-top: 0;
    }
  }
  &__icon {
    margin: 0 auto 0.5rem auto;
    color: #2b1f09;
  }
  &__progress {
    margin: 1rem 0;
  }
  &__inputs {
    display: flex;
    padding: 0 6rem 0 3rem;

    @media (max-width: 1050px) {
      width: 100%;
      border-left: none;
      border-top: none;
    }
  }
  &__submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    .required-text {
      color: #2b1f0a;
      font-family: Open Sans;
      font-weight: 400;
      font-size: 14px !important;

      @media screen and (min-width: 3200px) {
        font-size: 24px !important;
      }
    }
  }

  .Form.personal-information-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 1040px;
    overflow: hidden;
  }

  .form-section {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .mls-system-fields-container {
      width: 100%;
      .delete-mls-system {
        cursor: pointer;
        background-color: transparent;
        padding: 0;
        border: none;
        outline: none;
      }
    }

    .mls-selection-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      padding: 1rem;
      border-radius: 8px;
      border: 1px solid #DADADA;
      background: rgba(218, 218, 218, 0.20);

      .p-multiselect.p-component.p-inputwrapper {
        width: 100%;
        position: relative;

        .p-checkbox.p-component {
          width: 20px;
          height: 20px;

          &.p-highlight {
            .p-checkbox-box {
              border-color: #FFAB03;
              background-color: #FFFFFF;
            }
          }

          .p-checkbox-box {
            width: 20px;
            height: 20px;
            border-radius: 3px;
            border: 1px solid #DDD;
          }
        }

        &:not(.p-disabled) {
          &:hover, &.p-focus {
            box-shadow: none;
            border-color: #ffab03;
          }
        }

        .p-multiselect-filter-container {
          .p-inputtext:enabled {
            &:focus, &:hover {
              box-shadow: none;
              border-color: #ffab03;
            }
          }
        }

        .p-multiselect-items.p-component {
          .p-multiselect-item {
            span {
              color: #4F4F4F;
              font-family: "Open Sans", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            &:not(.p-highlight):not(.p-disabled).p-focus {
              background-color: #FFFDEA;
            }

            &.p-highlight {
              background-color: #FFFDEA;

              &.p-focus {
                background-color: #FFFDEA;
              }
            }
          }
        }
      }
    }

    .mls-name-zip-container {
      display: flex;
      width: 100%;

      > .input-wrapper {
        width: 50%;

        &.mls-system-name {
          .did-floating-input:disabled {
            color: #303030
          }
        }

        &:first-child {
          .did-floating-input {
            border-radius: 10px 0 0 10px;
          }
        }

        .did-floating-input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &-title {
      color: #111827;

      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }
}
