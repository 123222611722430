.table_wrapper {
  border-radius: 8px;
  .mainTable {
    > thead > tr:first-child > *:first-child {
      border-start-start-radius: 8px;
    }
    > thead > tr:first-child > *:last-child {
      border-start-end-radius: 8px;
    }

    > tbody > tr:last-child > *:first-child {
      border-end-start-radius: 8px;
    }
    > tbody > tr:last-child > *:last-child {
      border-end-end-radius: 8px;
    }
  }

  &:has(> .table-header) {
    .table-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .mainTable {
      .mainTable {
        > thead > tr:first-child > *:first-child {
          border-start-start-radius: 8px;
        }
        > thead > tr:first-child > *:last-child {
          border-start-end-radius: 8px;
        }
      }
    }
  }
}
.table_wrapper_orderline{
  margin-block: 0;
  .mainTable {
    > thead > tr:first-child > *:first-child {
      border-start-start-radius: initial;
    }
    > thead > tr:first-child > *:last-child {
      border-start-end-radius: 8px;
    }
  }
}
.table-header {
  padding-inline: 24px;
  padding-block: 12px;
  background-color: #ffffff;
}

.mainTable {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0 2px;
}

.table-loading {
  position: relative;

  > div > .table-loading-spinning {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    max-height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .table-spinning-text {
      color: #ffab03;
    }
  }

  .table-spin-container {
    position: relative;
    transition: opacity 0.3s;

    &.table-spin-blur {
      clear: both;
      opacity: 0.5;
      user-select: none;
      pointer-events: none;
    }

    .table-placeholder {
      background: #ffffff;
    }
  }
}

thead.customTableRow {
  tr {
    border-bottom: 1px solid #eaecf0;
  }
}

.customTableRow,
.customTableRowSelected {
  .mainTableTh {
    background: #ffffff;
    font-family: 'Open Sans';
    font-weight: 600 !important;
    color: #000000;
    font-size: 22px;
    padding: 1.15rem;
    letter-spacing: 1px;
    line-height: 1.375;
    text-align: start;
    white-space: nowrap;

    @media screen and (min-width: 3200px) {
      padding: 1.5rem;
      font-size: 28px;
    }
    @media screen and (max-width: 1750px) {
      padding: 1rem;
      font-size: 16px;
    }

    &-title {
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
    }

    &-sorter {
      display: flex;
      align-items: center;
    }

    &.selection {
      .mainTableTh-sorter {
        justify-content: center;
      }
    }
  }
}

.mainTableSelected {
  width: 100%;
  border-collapse: separate;
  border-radius: 20px;
  //   border-spacing: 8px 8px;
  border-spacing: 0 1px;
  background-color: #f0f0f0;
  overflow: hidden;
  margin-top: 1rem;
}
.mainTableTrSelected {
  border-collapse: collapsed;
  border-spacing: 2px;
  background-color: white;
  outline: 1px solid #ffab03;
  outline-offset: -3px;
  width: 150px;
  text-align: center;

  &:last-child {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

.mainTableTrSelected:hover {
  background-color: #fff7c9;
}
.mainTableTr {
  border-collapse: unset;
  border-spacing: 2px;
  background-color: white;
  width: 150px;
  text-align: center;
  border-bottom: 1px solid #eaecf0;
}

colgroup {
  col {
    width: initial;

    &.link {
      width: 5%;
    }
    &.preview {
      width: calc(36px + 1.15rem * 2);
      @media screen and (min-width: 3200px) {
        width: calc(36px + 1.5rem * 2);
      }
      @media screen and (max-width: 1750px) {
        width: calc(36px + 1rem * 2);
      }
    }
    &.actions {
      width: calc(77px + 1.15rem * 2);
      @media screen and (min-width: 3200px) {
        width: calc(77px + 1.5rem * 2);
      }
      @media screen and (max-width: 1750px) {
        width: calc(77px + 1rem * 2);
      }
    }
    &.audience-status {
      width: calc(30px + 1.15rem * 2);
      @media screen and (min-width: 3200px) {
        width: calc(30px + 1.5rem * 2);
      }
      @media screen and (max-width: 1750px) {
        width: calc(30px + 1rem * 2);
      }
    }
    &.selection-col {
      width: calc(22px * 1.375 + 1.15rem * 2);

      font-size: 22px;
      padding: 1.15rem;
      letter-spacing: 1px;
      line-height: 1.375;
      text-align: start;
      white-space: nowrap;

      @media screen and (min-width: 3200px) {
        width: calc(28px * 1.375 + 1.5rem * 2);
      }
      @media screen and (max-width: 1750px) {
        width: calc(16px * 1.375 + 1rem * 2);
      }

      &.selection-all-col {
        width: 90px;
      }
    }
  }
}

.p-menu {
  width: fit-content;
}

.select-all-dropdown.p-button.p-component.p-button-icon-only {
  width: fit-content;
  height: fit-content;
  padding: 0;
  background: transparent;
  color: #757575;
  line-height: 1;
  border: none;
  box-shadow: none;
}

.mainTableTdSelected,
.mainTableTd {
  text-align: start;
  padding: 1.15rem;
  position: relative;

  color: #757575;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 22px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.08px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;

  @media screen and (min-width: 3200px) {
    padding: 1.5rem;
    font-size: 30px;
  }
  @media screen and (max-width: 1750px) {
    padding: 1rem;
    font-size: 18px;
  }
}

.mainTableTr:not(.mainTableDisabled):hover {
  background-color: #fff7c9;
}
.mainTableTr.mainTableDisabled {
  cursor: not-allowed;
  .mainTableTdSelected,
  .mainTableTd {
    color: #d7d7d7;
  }
}

.table_checkbox {
  display: flex;
  justify-content: center;
  input[type='checkbox'] {
    position: relative;
    border: 1px solid #c6cbcd;
    border-radius: 4px;
    background: #f8f8f8;
    cursor: pointer;
    line-height: 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    margin: 0;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    opacity: 0.5;

    @media screen and (min-width: 3200px) {
      height: calc(24px * 1.5);
      width: calc(24px * 1.5);
    }
  }

  input[type='checkbox']:hover:not(:disabled) {
    opacity: 1;
  }

  input[type='checkbox']:checked {
    background-color: #ffff;
    border: 1px solid #c6cbcd;
    border-radius: 4px;
    opacity: 1;
  }

  input[type='checkbox']:checked:before {
    content: '';
    position: absolute;
    inset-inline-start: 25%;
    top: 50%;
    transform: rotate(45deg) translate(-50%, -50%);
    width: calc((22px * 1.15 / (22 * 1.15 / (16 / 14))) * 6);
    height: calc((22px * 1.15 / (22 * 1.15 / (16 / 14))) * 9.6);
    border: 2px solid #ffab03;
    border-top: 0;
    border-inline-start: 0;
    z-index: 2;
    @media screen and (min-width: 3200px) {
      width: calc((28px * 1.15 / (28 * 1.15 / (16 / 14) - 2)) * 10);
      height: calc((28px * 1.15 / (28 * 1.15 / (16 / 14) - 2)) * 16);
    }

    @media screen and (max-width: 1750px) {
      width: calc((20px * 1.15 / (20 * 1.15 / (16 / 14))) * 5);
      height: calc((20px * 1.15 / (20 * 1.15 / (16 / 14))) * 8);
    }
  }
}
th:nth-child(1) .sortedIconChecked {
  display: none;
}
th:last-child .sortedIcon {
  display: none;
}
.sortedIcon {
  display: flex;
  align-items: center;
  margin-left: 8px;
  img {
    width: 10px;
    @media screen and (min-width: 3200px) {
      width: 18px;
    }
  }
}
