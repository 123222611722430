.orderline-action-modal {
  position: relative;

  .Modal__scrollable-content {
    padding: 2rem 5rem;

    .action-icon-wrapper {
      width: 65px;
      height: 65px;
      border-radius: 100%;

      &.danger {
        background: #FFF0F1;
      }

      &.primary {
        background: #FFF2D5;
      }
    }

    .orderline-action-title {
      color: #262626;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .orderline-action-description {
      max-width: 310px;
      color: #757575;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}