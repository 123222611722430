.campaign-gaol-card {
  padding: 20px 12px;
  border-radius: 10px;
  border: 1px solid var(--Neutrals-200, #D1D1D1);
  background: #FFF;

  &.checked {
    border-color: #007B94;
  }

  .campaign-goal-radio-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #D1D1D1;
    cursor: pointer;
    background: #FFFFFF;
    outline: none;
    transition: .3s all;
    padding: 0;

    .campaign-goal-radio-button-inner {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #007B94;
    }
    &:hover, &.checked:hover {
      border-color: #0b687f;
      .campaign-goal-radio-button-inner {
        background: #0b687f;
      }
    }

    &.checked {
      border-color: #007B94;
      .campaign-goal-radio-button-inner {
        background: #007B94;
      }
    }
  }

  &-name {
    color: var(--Light-100, #2B1F0A);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &-description {
    color: #7E7E7E;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}