@import 'tokens.scss';

.CampaignSetup {
  height: 100%;

  .table-audience-name.Text {
    @media screen and (min-width: 3200px) {
      font-size: 32px !important;
    }
  }

  svg.table-audience-icon,
  span.table-audience-icon {
    font-size: 71px;
    @media screen and (min-width: 3200px) {
      font-size: 100px !important;
    }
  }

  svg.table-audience-icon-small,
  span.table-audience-icon-small {
    font-size: 24px;
    @media screen and (min-width: 3200px) {
      font-size: 100px !important;
    }
  }


  img.table-audience-icon {
    height: 71px;
    @media screen and (min-width: 3200px) {
      height: 100px !important;
    }
  }
  &__main_table_camp {
    border-collapse: collapsed !important;
    width: 100% !important;
  }
  &__header {
    background: var(--color-white);
    border-radius: 22px !important;
  }
  &__sendAdvertise {
    padding: 4px 2rem !important;
    // width: 252px;
    // height: 54px;
  }
  &__boxButtonSizes {
    margin: 1rem auto;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex;
    align-items: center;
    text-align: center;

    color: #2b1f0a !important;
    padding: 0;
    padding-inline: 12px;
    @media (max-width: 1560px) {
      width: 150px !important;
      font-size: 10px !important;
      height: 39px;
    }
  }
  &__boxButtonSize {
    margin: 1rem auto;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    display: flex;
    align-items: center;
    text-align: center;

    color: #2b1f0a !important;
    padding: 0;
    padding-inline: 12px;
    width: 254px !important;
    height: 54px;
    @media (max-width: 1560px) {
      width: 176px !important;
      font-size: 10px !important;
      height: 40px;
    }
  }
  &__subheading {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.25 !important;
    color: #2B1F0A !important;
    padding: 18px 0 12px;


    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }
  &__bordered{
    border-bottom: 1px solid #DBDBDB;
  }
  &__titleSpace {
    @media (max-width: 699px) {
      padding-top: 2rem;
    }
  }
  &__title {
    @media (max-width: 699px) {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px !important;
    }
  }
  &__campaign_use_total_prospects {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    padding: 20px 40px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    text-align: center;
    color: #dc9300 !important;
    border: 1px solid #dc9300 !important;
    background: rgba(255, 171, 3, 0.07) !important;
    border-radius: 10px !important;
    width: 212px;
    height: 54px;

    @media screen and (min-width: 3200px) {
      font-size: 29px;
      width: auto;
      height: auto;
    }
  }
  &__backbutton {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 21.5205px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Error 400 */
    background: rgba(201, 34, 45, 0.08);
    border-radius: 10px;
    color: #bd0000 !important;
    border: 1px solid #bd0000;
    // :hover {
    //   background-color: green !important;
    // }
  }

  &__modalIcon {
    position: absolute;
    top: 10px;
    right: 30px;
  }
  &__next-button {
    margin-left: 2rem;

    @media (max-width: 699px) {
      margin-left: 0;
    }
  }

  .CampaignCreationFooter {
    @media (max-width: 699px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }
  }
  &__targeted_audience {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 1.25;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6d6d6d !important;

    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }

  &__bottom_details {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 4rem;

    .prospects-selected {
      svg {
        font-size: 17px;

        @media screen and (min-width: 3200px) {
          font-size: 27px;
        }
      }

      span {
        @media screen and (min-width: 3200px) {
          font-size: 28px !important;
        }
      }
    }

    @media screen and (max-width: 699px) {
      margin-left: 0;
    }
  }

  &__progress {
    margin-left: auto;
    @media (max-width: 699px) {
      width: 100%;
    }
  }
  &__modalSpace {
    margin: 0.3rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;

    @media (max-width: 699px) {
      width: 300px;
    }

    @media (min-width: 1920px) {
      margin: 0.3rem 10rem;
    }

    @media screen and (min-width: 3200px) {
      margin: 0.3rem 10rem;
      height: auto;
    }

    .create_new_campaign {
      margin: 2rem auto 0 auto;
      margin-block-start: 25px;
      width: 309px;
      height: 54px;

      @media screen and (min-width: 3200px) {
        width: auto;
        height: auto;
      }
    }
  }
  &__heading_container {
    text-align: center;
    margin-bottom: 9px;

    @media screen and (max-width: 699px) {
      margin-bottom: 9px;
      margin-top: 0.5rem;
    }

    .Text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 40px !important;
      line-height: 54px;
      text-align: center;
      @media screen and (min-width: 3200px) {
        font-size: 60px !important;
      }

      @media screen and (max-width: 699px) {
        font-size: 22px !important;
      }
    }
  }

  &__name {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0 0 1rem 0;
  }
  &__input-label {
    margin: 1rem 0 0.25rem 0;
  }
  &__name-input {
    max-width: 40rem;
  }
  &__audiences {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: var(--border-radius-xl);

    @media screen and (min-width: 3200px) {
      padding: 1.25rem 2.5rem;
      border-radius: var(--border-radius-xxl);
    }

    .heading {
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 24px !important;
      line-height: 1.25 !important;
      color: #2b1f0a;

      @media screen and (min-width: 3200px) {
        font-size: 34px !important;
      }
      @media screen and (max-width: 699px) {
        font-size: 17px;
      }
    }
  }
  &__audience-list {
    margin-top: 10px;
    @media screen and (min-width: 3200px) {
      margin-top: 30px;
    }
  }
  &__empty {
    padding: 0 0 1rem;
    gap: 0.75rem;
    display: flex;
    justify-items: center;
    justify-content: center;
    background-color: white;

    @media screen and (min-width: 3200px) {
      padding: 0 0 3rem;
      gap: 10rem;
      justify-content: space-evenly;
    }

    @media screen and (max-width: 699px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__box {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem;
    border: 1.5px solid #f0f0f0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    &__content-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__icon {
      height: 50px;
      @media screen and (min-width: 3200px) {
        height: 140px;
      }
    }

    @media screen and (min-width: 3200px) {
      padding: 1.5rem 3rem;
    }

    @media screen and (max-width: 699px) {
      width: 100%;
      border: 3.5px solid #aab2b5;
      border-radius: 10px;
    }

    &__icon_container {
      margin: 1rem auto;
    }
  }

  &__boxText {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__boxtext_size {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 1.25;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6d6d6d !important;

    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }
  }
  &__boxsizeButton {
    width: 252px;
    height: 54px;
  }
  &__boxTextButton {
    @media (max-width: 1560px) {
      font-size: 13px;
    }
    padding: 0 5px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    /* Light 100 */

    color: #2b1f0a;

    @media screen and (min-width: 3200px) {
      font-size: 26px;
    }

    @media (max-width: 1050px) {
      font-size: 12px;
    }
  }
  &__boxButton {
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;
    color: #1ba1c5;

    @media screen and (max-width: 600px) {
      // width: 180px !important;
      background: green !important;
    }
    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: #1ba1c5;

      // color: $text-on-primary--vivid;
      box-shadow: none;
    }
  }

  &__Choose_your_Creative {
    width: 100%;
  }

  .prospects_count {
    @media screen and (min-width: 3200px) {
      font-size: 26px !important;
    }
  }
  .prospect-info {
    font-size: 10px;
    @media screen and (min-width: 3200px) {
      font-size: 24px !important;
    }
  }
  &__center_prospect_container {
    @media (max-width: 699px) {
      display: none;
    }
  }
  &__tableHeader {
    border: 1px solid #c6cbcd;
  }
  &__tableRow {
    border: 1px solid #c6cbcd;
    border-right: 1px solid #c6cbcd;
  }
  &____tableCheckbox {
    background: skyblue;
  }
  // &__tablePagination {
  //   margin: 0 5px;
  //   background: #fff;
  //   border: 1px solid #c6cbcd;
  //   border-radius: 5px;
  // }
  &__NoAudience {
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #f0f0f0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &__NoAudience_titles {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000 !important;
  }

  // .PageHeader {
  //   @media (max-width: 699px) {
  //     gap: 2.2rem;
  //   }
  // }
}
.mb-8px {
  margin-top: 7px;
  margin-bottom: 8px;
}
.bg-content-color {
  background-color: #f8f8f8;
}

.info-box{
  padding: 24px;
  border-radius: 12px;
  border: 1px solid  #D1D1D1;
  background:  #FFFDEA;
  font-size: 16px;
  margin-block: 12px;
  .info-desc{
    padding: 0;
    margin: 0;
  }
  .info-desc:first-child{
    color:#262626;
  }
  .info-desc:last-child{
    color:#B0B0B0;
  }
}

.section-wrapper{
  display:flex;
  flex-direction:column;
  gap:19px;
  .goal-block{
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 8px
  }
  .section-title{
    color: #444;
  }
  .section-audience-count{
    color:#888;
    padding-inline:4px;
  }
  .toggle-goal{
    border:none;
    background-color: transparent;
    color: #444;
    font-size: 16px;
  }
  .campaign_action_btn {
    border-color: #007b94;
    color: #007b94;
    padding: 20px 0;
    font-size: 16px;
  }
.empty-state-wrapper {
  background-color: #F6F6F6;
  padding:22px 12px;
  margin-top: 19px;
  .budge{
    color:#D9D9D9;
    font-size:24px
  }
  .empty-goal {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: #757575;

    &.title {
      padding-block: 8px;
      font-weight: 700;
    }

    &.desc {
      font-weight: 400;
      padding-bottom: 24px;
    }
  }
}
}


