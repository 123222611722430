.alert_item{
  padding: 14px 24px;
  border-radius: 10px;
  border: 1px solid  #E7E7E7;
  background: #FFF;
  width: calc(50% - 8px);
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  .alert-heading{
    width: 100%;
  }
  .alert-redirect{
    text-decoration:none;
  }

}
.orderline-list-menu.p-menu.p-component {
  padding-inline: 8px;
  padding-block: 6px;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(225, 225, 226, 0.5);

  .p-menu-list.p-reset {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .p-menuitem {
      &:not(.p-highlight):not(.p-disabled) {
        > .p-menuitem-content:hover {
          background: #fff9ed;
        }

        &.p-focus {
          > .p-menuitem-content {
            background: #ffffff;

            &:hover {
              background: #fff9ed;
            }
          }
        }
      }

      .p-menuitem-content {
        .p-menuitem-link {
          .alert-redirect{
            text-decoration:none;
            display: flex;
            gap:8px
          }
        }
      }

    }
  }
  .truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
}