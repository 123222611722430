.orderline-details-container {
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  background: #FFFFFF;
  box-shadow: 0 3px 26px 0 rgba(192, 192, 192, 0.25);

  &-title {
    color: #262626;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}