.Modal__content.crop-image-modal {
  position: relative;
  border-radius: 6px;
  width: 680px;
  padding: 2rem 4rem;

  .Modal__scrollable-content {
    display: flex;
  }

  .image-cropper-wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    .image-cropper-container {
      position: relative;
      width: 320px;
      height: 320px;

      .reactEasyCrop_CropArea {
        color: rgba(0, 0, 0, 0.7)
      }
    }
  }

  .zoom-slider.p-slider.p-component.p-slider-horizontal {
    width: 13rem;

    &:not(.p-disabled) .p-slider-handle:hover {
      background: #007B94;
    }

    .p-slider-range {
      background: #007B94;
    }

    .p-slider-handle {
      border-color: #007B94;
    }
  }

  .crop-image-modal-title {
    color: #000;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .crop-image-modal-description {
    color: #757575;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 530px;
  }
}