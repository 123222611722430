.popover {
  position: absolute;
  z-index: 2;
  bottom: 50px;
}
.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}