.audience-card-container {
  padding: 1rem 1.5rem;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  background: #FEFEFE;

  .lens-img {
    width: 26px;
    height: 26px;
  }

  .audience-name {
    color: #262626;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .prospects-count-value {
    color: #262626;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }

  .prospects-count-label {
    color: #757575;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
}