.table-tabs{
  display: flex;
  gap:10px;
  margin-top:16px;
  button{
    padding: 18px 24px;
    border: none;
    background-color: #FFF;
    box-shadow: 0 3px 26px 0 rgba(192, 192, 192, 0.25);
    border-radius: 10px 10px 0 0;
    font-size: 16px;
    &.active{
      color: #007B94;
      font-weight: 700;
    }
    &.inactive{
      background-color: #F7F7F7;
      color: #9D9D9D;
      font-weight: 400;
    }
  }
}

.orderline-info-header-wrapper {
  padding: 18px 24px;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0 3px 26px 0 rgba(192, 192, 192, 0.25);
  .orderline-header-info{
    display: flex;
    flex-direction: column;
    gap:12px;
    .title{
      color: #2B1F0A;
      font-size: 18px;
      font-weight: 700;
    }
    .flight-dates{
      color: #757575;
      font-size: 16px;
      font-weight: 400;
      .dates{
        font-weight: 700;
      }
    }
  }


}
.orderline-actions-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .p-button.p-component.orderline-action-btn {
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 18px;
    width: fit-content;
    box-shadow: unset;
  }
}
.orderline-actions-menu.p-menu.p-component {
  padding-inline: 8px;
  padding-block: 6px;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(225, 225, 226, 0.5);

  .p-menu-list.p-reset {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .p-menuitem {
      &:not(.p-highlight):not(.p-disabled) {
        > .p-menuitem-content:hover {
          background: #fff9ed;
        }

        &.p-focus {
          > .p-menuitem-content {
            background: #ffffff;

            &:hover {
              background: #fff9ed;
            }
          }
        }
      }

      .p-menuitem-content {
        border-radius: 5px;

        .p-menuitem-link {
          border-radius: 5px;
          padding-inline: 8px;
          padding-block: 7px;
          gap: 10px;

          .p-menuitem-text {
            color: #222124;
            font-variant-numeric: lining-nums tabular-nums;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Open Sans', serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.07px;
          }
        }
      }

      &.delete {
        .p-menuitem-content {
          .p-menuitem-link {
            .p-menuitem-text {
              color: #ed6762;
            }
          }
        }
      }
    }
  }
}