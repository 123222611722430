@import 'src/tokens';
@import 'src/mixins';

.orderline-details {
  height: 100%;
  width: 100%;

  &-header {
    padding: 18px 24px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0 3px 26px 0 rgba(192, 192, 192, 0.25);
  }

  &-name {
    color: #2B1F0A;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &-flight_dates {
    color: #757575;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .orderline-actions-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .p-button.p-component.orderline-action-btn {
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 18px;
      width: fit-content;
      box-shadow: unset;
    }
  }
}

.orderline-actions-menu.p-menu.p-component {
  padding-inline: 8px;
  padding-block: 6px;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(225, 225, 226, 0.5);

  .p-menu-list.p-reset {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .p-menuitem {
      &:not(.p-highlight):not(.p-disabled) {
        > .p-menuitem-content:hover {
          background: #fff9ed;
        }

        &.p-focus {
          > .p-menuitem-content {
            background: #ffffff;

            &:hover {
              background: #fff9ed;
            }
          }
        }
      }

      .p-menuitem-content {
        border-radius: 5px;

        .p-menuitem-link {
          border-radius: 5px;
          padding-inline: 8px;
          padding-block: 7px;
          gap: 10px;

          .p-menuitem-text {
            color: #222124;
            font-variant-numeric: lining-nums tabular-nums;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Open Sans', serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.07px;
          }
        }
      }
    }
  }
}