.adType{
  width:100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px;
  background-color: #FFF;
  &__ad-type{
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content:space-between;

  }
  &__ad-type-text{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
    gap:17px

  }
  &__ad-box {
    height: 24px;
    width: 24px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 11px;
    @media screen and (min-width: 3200px) {
      height: 44px;
      width: 44px;
      margin-right: 20px;
    }
  }
  &__pieChart {
    width: 120px;
  }
  &__label {
    font-weight: 600;
    font-size: 14px;
    color: #000000;

    @media screen and (min-width: 3200px) {
      font-size: 28px !important;
    }

    @media screen and (max-width: 699px) {
      font-size: 12px !important;
    }
  }
  .noData-ad-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
  }

}