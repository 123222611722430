.creative-card-container {
  min-width: 0;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  background-color: #FFF;

  .creative-name {
    color: #262626;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .creative-preview-container {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    background: #EEEEEE;
    padding: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .creative-stat-label, .creative-stat-value, .creative-specs {
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .creative-specs {
    color: #262626;
    font-size: 16px;
    font-weight: 600;
  }

  .creative-stat-label {
    color: #757575;
  }

  .creative-stat-value {
    color: #111827;
  }
}