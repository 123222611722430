.CampaignPerformance__loader_campain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}
.CampaignPerformance__title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 1.5;

  color: #000000 !important;

  @media screen and (min-width: 3200px) {
    font-size: 28px !important;
  }

  @media screen and (max-width: 699px) {
    font-size: 12px !important;
    line-height: 15px;
  }
}
.CampaignPerformance__contentModal {
  font-family: 'Open Sans';
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 40px !important;
  line-height: 54px !important;
  text-align: center !important;
  color: #2b1f0a !important;
  margin-bottom: 20px;
}

.CampaignPerformance__select_campaign {
    border-color: #007b94;
    color: #007b94;
    font-size: 16px;
    background-color: #FFF;
    padding: 16px 11px;
    font-weight: 700;
  &:hover{
    border-color: #007b94 !important;
    color: #FFF !important;
    background-color:#007b94 ;
  }

  @media screen and (min-width: 3200px) {
    width: auto;
    height: auto;
  }
}
.CampaignPerformance__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .Modal__scrollable-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .select-modal-footer {
    margin-top: 1rem;
  }
}

.CampaignPerformance__submitModal {
  width: 1083px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 0;

  @media screen and (max-width: 1500px) {
    width: 883px;
    height: 670px !important;
  }
  @media screen and (max-width: 1050px) {
    max-width: 650px;
  }
  @media screen and (max-width: 650px) {
    max-width: 420px;
    align-items: flex-start;
    overflow-x: auto;
  }
  @media screen and (max-width: 450px) {
    max-width: 320px;
  }
}

.spinner-performance-list {
  color: #fcd937;
  position: fixed;
  z-index: 999;
  margin: auto;
  top: 411px;
  left: 0;
  bottom: 0px;
  right: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
