.prospect_count_item{
  border-radius: 12px;
  border: 1px solid  #E7E7E7;
  padding:  24px;
}
.budget_adv_control_modal {
  .Modal__scrollable-content{
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.SavedAudiences.scrolable-table{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden auto;
}