.edit-creative-modal{
  padding:0;
.modal_heading{
  display: block;
  padding-bottom: 32px;
}
.audience_edit_content{
  background-color: #FFF;
  height: 100px;
  .confirm-modal-footer{
    background-color: #F6F6F6;
  }
}}

.attached_audiences_content{
  margin-inline: 32px;
  border-radius: 12px;
  border: 1px solid #E7E7E7;
  max-height: 520px;
  overflow-y: auto;
  .attached_count{
    padding:12px 24px;
    border-bottom: 1px solid #E7E7E7;
  }
}